import {forwardRef} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {media} from '../../../utils'


const TimelineWrapper = styled.div`
  overflow: hidden;
`

const StyledTimeline = styled.ul`
  display: grid;
  grid-auto-flow: column;
  gap: 12rem;
  margin: 0;

  padding-inline-start: 6.5rem;
  padding-inline-end: calc(24rem + 24rem + 3rem);
  padding-block-start: 0.5rem;
  padding-block-end: 1rem;

  overflow-x: scroll;
  overscroll-behavior-inline: contain;
  scroll-snap-type: inline mandatory;
  scroll-padding-left: 10rem;
  scroll-padding-right: 10rem;
  scroll-padding-inline: 10rem;

  &::-webkit-scrollbar {
    display: none;
  }

  @supports (scrollbar-width: none;) {
    scrollbar-width: none;
  }

  @media (prefers-reduced-motion: no-preference) {
    & {
      scroll-behavior: smooth;
    }
  }

  ${media.down('mobile')(`
    padding-inline-start: 0;
    padding-inline-end: 5rem;
  `)}
`

const Timeline = forwardRef(({children, ...props}, ref) => {
  return (
    <TimelineWrapper>
      <StyledTimeline ref={ref} {...props}>
        {children}
      </StyledTimeline>
    </TimelineWrapper>
  )
})

Timeline.displayName = 'Timeline'

Timeline.propTypes = {
  children: PropTypes.node,
}

export default Timeline
