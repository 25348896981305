import PropTypes from 'prop-types'
import styled from 'styled-components'
import settings from '../../../../content/settings/general.json'
import {media} from '../../../utils'
import ChevronButton from '../ChevronButton'


const StyledTimelineSwitcher = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: flex-start;
  padding: 3rem 6.5rem;

  ${media.down('mobile')(`
    padding: 3rem 1.5rem;
  `)}
`

const TimelineSwitcher = ({activeIndex, count, lang, onClick}) => {
  return (
    <StyledTimelineSwitcher>
      <ChevronButton left disabled={activeIndex === 0} onClick={() => onClick(-1)}>
        {settings[lang].timeline.chevronLeftSrOnly}
      </ChevronButton>
      <ChevronButton disabled={activeIndex === count - 1} onClick={() => onClick(1)}>
        {settings[lang].timeline.chevronRightrOnly}
      </ChevronButton>
    </StyledTimelineSwitcher>
  )
}

TimelineSwitcher.propTypes = {
  activeIndex: PropTypes.number,
  count: PropTypes.number,
  lang: PropTypes.string,
  onClick: PropTypes.func,
}

export default TimelineSwitcher
