import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {convertMDContent, media} from '../../../utils'
import Typography from '../Typography'


const StyledTimelineItem = styled.li`
  display: inline-block;
  transition: opacity ${({theme}) => theme.global.duration} ease-in;
  opacity: 0.3;
  width: 24rem;

  ${({active}) => active && css`
    opacity: 1;
  `}
`

const StyledDot = styled.span`
  display: inline-block;
  position: relative;
  margin: 1rem 0;
  border: 1px solid ${({theme}) => theme.colors.primary};
  border-radius: 50%;
  background-color: ${({theme}) => theme.colors.white};
  width: 1rem;
  height: 1rem;

  &:not(:last-child) {
    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      transition:
        background-color ${({theme}) => theme.global.duration} ease-in,
        opacity ${({theme}) => theme.global.duration} ease-in;
      opacity: 0.2;
      z-index: -1;
      background-color: ${({theme}) => theme.colors.dark};
      width: calc(24rem + 12rem);
      height: 1px;
      content: '';

      ${({isLineActive}) => isLineActive && css`
        background-color: ${({theme}) => theme.colors.primary};
        opacity: 1;
      `}

      ${media.down('mobile')(`
        width: calc(24rem + 12rem + 3rem);
      `)}
    }
  }

  ${({active}) => active && css`
    background-color: ${({theme}) => theme.colors.primary};
  `}
`

const StyledWrapper = styled.a`
  cursor: default;
  color: inherit;
  pointer-events: none;

  &:focus {
    outline: 1px solid ${({theme}) => theme.colors.primary};
  }

  &:last-child {
    ${StyledDot}::before {
      content: unset;
    }
  }

  ${media.down('mobile')(`
    padding: 0 1.5rem;
  `)}
`

const TimelineItem = ({index, timelineIndex, year, active, children}) => {
  const isLineActive = index < timelineIndex


  return (
    <StyledWrapper href="#">
      <Typography variant="h3" type="h2" isHeading>{year}</Typography>
      <StyledDot active={active} isLineActive={isLineActive} />
      <StyledTimelineItem index={index} active={active}>
        <Typography type="div">
          {convertMDContent(children)}
        </Typography>
      </StyledTimelineItem>
    </StyledWrapper>
  )
}

TimelineItem.propTypes = {
  index: PropTypes.number,
  timelineIndex: PropTypes.number,
  year: PropTypes.string,
  active: PropTypes.bool,
  children: PropTypes.node,
}

export default TimelineItem
