import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import {mapValues, map} from 'lodash-es'
import {useInView} from 'react-intersection-observer'
import For from 'babel-plugin-jsx-control-statements'
import useLang from '../hooks/useLang'
import AppShell from '../components/containers/AppShell'
import Navbar from '../components/containers/Navbar'
import SubpageHero from '../components/molecules/SubpageHero'
import SubpageDescription from '../components/molecules/SubpageHero/SubpageDescription'
import SubpageSubDescription from '../components/molecules/SubpageHero/SubpageSubDescription'
import SectionOurTeam from '../components/molecules/SectionOurTeam'
import SideTitleSection from '../components/containers/SideTitleSection'
import CardsContainer from '../components/molecules/Card/CardsContainer'
import Card from '../components/molecules/Card'
import Timeline from '../components/atoms/Timeline'
import TimelineItem from '../components/atoms/TimelineItem'
import TimelineSwitcher from '../components/atoms/TimelineSwitcher'
import useTimeline from '../hooks/useTimeline'


const About = ({data, pageContext: {lang: pageLang}}) => {
  const langToSlug = mapValues(data?.markdownRemark.frontmatter, 'slug')
  const {lang} = useLang(pageLang, langToSlug)
  const [ref, inView] = useInView({
    threshold: 1,
    triggerOnce: true,
    delay: 500,
  })
  const {timelineRef, activeIndex, handleTimelineScroll, changeActiveIndex, isIndexActive} = useTimeline()
  const pageData = data.markdownRemark.frontmatter[lang]
  const timeline = map(pageData.timeLine, (data, index) => ({...data, index}))

  return (
    <AppShell title={pageData.menuText} lang={lang} langToSlug={langToSlug} seo={pageData.seo}>
      <Navbar title={pageData.menuText} lang={lang} langToSlug={langToSlug} />
      <SubpageHero
          title={pageData.hero.title}
          fadedSideText={pageData.hero.sideTextNumber}
          sideText={pageData.hero.sideText}
      >
        <SubpageDescription>
          {pageData.hero.description}
        </SubpageDescription>
        <SubpageSubDescription small>
          {pageData.hero.subDescription}
        </SubpageSubDescription>
      </SubpageHero>
      <main>
        <Timeline
            ref={timelineRef}
            onScroll={handleTimelineScroll}
        >
          <For each="timelineItem" of={timeline}>
            <TimelineItem
                index={timelineItem.index}
                timelineIndex={activeIndex}
                key={timelineItem.index}
                year={timelineItem.year}
                active={isIndexActive(timelineItem.index)}
            >
              {timelineItem.text}
            </TimelineItem>
          </For>
        </Timeline>
        <TimelineSwitcher
            activeIndex={activeIndex}
            count={timeline.length}
            lang={lang}
            onClick={changeActiveIndex}
        />
        <CardsContainer
            mission={pageData.mission}
            vision={pageData.vision}
            ref={ref}
        >
          <Card
              src={pageData.vision.image.image}
              alt={pageData.vision.image.alt}
              title={pageData.vision.title}
              description={pageData.vision.text}
              open={inView}
          />
          <Card
              src={pageData.mission.image.image}
              alt={pageData.mission.image.alt}
              title={pageData.mission.title}
              description={pageData.mission.text}
              open={inView}
          />
        </CardsContainer>
        <SideTitleSection
            title={pageData.ourTeam.title}
            mobileTitleVisible
            mobileFadedSideText
            fadedSideText={pageData.ourTeam.sideText}
        >
          <SectionOurTeam
              lang={lang}
              description={pageData.ourTeam.description}
              teamButtonText={pageData.ourTeam.teamButton.text}
              teamButtonLink={pageData.ourTeam.teamButton.link}
              team={pageData.ourTeam.team}
          />
        </SideTitleSection>
      </main>
    </AppShell>
  )
}

export const data = graphql`
  query About($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        ...AboutPageFragment
      }
    }
  }
`

About.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    lang: PropTypes.string.isRequired,
  }).isRequired,
}

export default About
