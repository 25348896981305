import {forwardRef} from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {media} from '../../../utils'


const StyledCardsContainer = styled.div`
  display: flex;
  margin: 6.125rem 0;
  width: 100%;

  ${media.down('mobile')(css`
    flex-direction: column;
    margin: 3rem 0;
    padding-bottom: 3rem;
  `)}
`

const CardsContainer = forwardRef(({children, ...props}, ref) => {
  return (
    <StyledCardsContainer ref={ref} {...props}>
      {children}
    </StyledCardsContainer>
  )
})

CardsContainer.displayName = 'CardsContainer'

CardsContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CardsContainer
