import React from 'react'
import {For} from 'babel-plugin-jsx-control-statements'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {media} from '../../../utils'
import PrimaryButton from '../../atoms/PrimaryButton'
import Typography from '../../atoms/Typography'
import Card from '../Card'
import CardsContainer from '../Card/CardsContainer'


const StyledSectionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 6.125rem;
  padding-left: 12.5rem;
  width: 100%;
  height: auto;

  ${media.down('mobile')(css`
    flex-direction: column;
    padding-left: 0;
    margin-top: 4.375rem;
  `)}
`

const StyledDescriptionContainer = styled.div`
  width: 80%;

  ${media.down('mobile')(css`
    width: 100%;
    padding: 0 1.5rem;
  `)}
`

const StyledDescription = styled(Typography)`
  width: 50%;

  ${media.down('mobile')(css`
    width: 100%;
  `)}
`

const StyledButtonContainer = styled.div`
  width: 20%;

  ${media.down('mobile')(css`
    width: 100%;
    padding: 3rem 1.5rem;
  `)}
`

const StyledCardsContainer = styled(CardsContainer)`
  margin: 6.125rem 0 -5.25rem 0;

  ${media.down('mobile')(css`
    padding-bottom: 0;
  `)}
`

const StyledCard = styled(Card)`
  z-index: 1;
  height: 38rem;

  img {
    object-position: 50% 10%;
  }

  span {
    margin-top: auto;
    margin-bottom: 3rem;
  }

  ${media.down('mobile')(css`
    height: 50rem;
  `)}
`

const SectionOurTeam = ({description, teamButtonText, teamButtonLink, team, ...props}) => {
  return (
    <StyledSectionContainer {...props}>
      <StyledDescriptionContainer>
        <StyledDescription variant="p" type="h5" thin>
          {description}
        </StyledDescription>
      </StyledDescriptionContainer>
      <StyledButtonContainer>
        <PrimaryButton to={teamButtonLink}>
          {teamButtonText}
        </PrimaryButton>
      </StyledButtonContainer>
      <StyledCardsContainer>
        <For each="person" of={team} index="idx">
          <StyledCard
              key={idx}
              src={person.image.image}
              alt={person.image.alt}
              title={person.name}
              subTitle={person.position}
          />
        </For>
      </StyledCardsContainer>
    </StyledSectionContainer>
  )
}

SectionOurTeam.propTypes = {
  description: PropTypes.string.isRequired,
  teamButtonText: PropTypes.string.isRequired,
  teamButtonLink: PropTypes.string.isRequired,
  team: PropTypes.array.isRequired,
}

export default SectionOurTeam
