import {useEffect, useRef, useState} from 'react'
import {map, debounce, indexOf, uniq} from 'lodash-es'
import {getClosest} from '../utils'


const useTimeline = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const timelineRef = useRef(null)
  const offsetMap = uniq(timelineRef.current ? [0, ...map(timelineRef.current.children, 'offsetLeft')] : [0])
  const debouncedSetActiveIndex = debounce(setActiveIndex, 150)

  useEffect(() => {
    if (timelineRef.current) {
      timelineRef.current.scrollLeft = offsetMap[activeIndex]
    }
  }, [activeIndex])

  const handleTimelineScroll = (e) => {
    if (typeof window !== undefined) {
      window.requestAnimationFrame(() => {
        const closestOffset = getClosest(offsetMap, e.target.scrollLeft)
        debouncedSetActiveIndex(indexOf(offsetMap, closestOffset))
      })
    }
  }

  const isIndexActive = (currentIndex) => {
    if (timelineRef?.current?.scrollLeft === 0 && currentIndex === 0) return true

    return currentIndex === activeIndex
  }

  const changeActiveIndex = (variation) => {
    if (activeIndex + variation === offsetMap.length) {
      return
    }

    if (activeIndex + variation < 0) {
      return
    }

    debouncedSetActiveIndex((activeIndex) => activeIndex + variation)
  }

  return {
    timelineRef,
    activeIndex,
    handleTimelineScroll,
    isIndexActive,
    changeActiveIndex,
  }
}

export default useTimeline
