import React from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {isEmpty} from 'lodash-es'
import {If} from 'babel-plugin-jsx-control-statements'
import {media} from '../../../utils'
import theme from '../../../theme/theme'
import Typography from '../../atoms/Typography'
import Image from '../../atoms/Image'


const StyledImageContainer = styled.div`
  position: relative;
  transition: background ${theme.global.duration} ease-in-out;
  background: ${({open}) => open ? `${theme.colors.dark}70` : 'transparent'};
  width: 50%;
  height: 22rem;
  color: ${theme.colors.textInverted};

  ${media.down('mobile')(css`
    width: 100%;
  `)}
`

const StyledImage = styled(Image)`
  /* Rewrites inline styles provided by gatsby-plugin-image */
  position: absolute !important;
  z-index: -1;
  width: 100%;
  height: 100%;

  ${media.down('mobile')(css`
    height: 100%;
  `)}
`

const StyledContentWrapper = styled.div`
  display: flex;
  position: absolute;
  right: 2rem;
  left: 2rem;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const StyledDescription = styled(Typography)`
  transform: ${({open}) => open ? 'translateY(2rem)' : 'translateY(4rem)'};
  transition: opacity ${theme.global.duration} ease-in-out, transform ${theme.global.duration} ease-in-out;
  opacity: ${({open}) => open ? '1' : '0'};
`

const StyledTitle = styled(Typography)`
  transform: ${({open}) => open ? 'translateY(-4rem)' : 'translateY(-2rem)'};
  transition: transform ${theme.global.duration} ease-in-out;
`

const Card = ({open, src, alt, description, subTitle, title, ...props}) => {
  return (
    <StyledImageContainer open={open} {...props}>
      <StyledImage image={src} alt={alt} />
      <StyledContentWrapper>
        <StyledDescription open={open} type="span" bold>{description}</StyledDescription>
        <If condition={!isEmpty(subTitle)}>
          <Typography variant="span" type="p">{subTitle}</Typography>
        </If>
        <StyledTitle open={open} type="h3">{title}</StyledTitle>
      </StyledContentWrapper>
    </StyledImageContainer>
  )
}

Card.propTypes = {
  open: PropTypes.bool,
  src: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
  description: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default Card
